import React, { memo } from 'react';
import styled from 'styled-components';

import Typography from '../../../Atomic/Typography';

const Container = styled.div`
  --bg-color: ${props => props.theme.colors.gradient};

  display: flex;
  position: relative;
  flex-direction: column;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.theme.colors.border};
  background: var(--bg-color);
  border-radius: calc(var(--default-border-radius));
  padding: ${props => props.theme.sizes.xxl * 2}px ${props => props.theme.sizes.xxl}px;

  --after-background: transparent;

  &:hover {
    --after-background: rgba(255, 255, 255, 0.05);
  }

  cursor: pointer;
  font-weight: bold;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;

  ::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: var(--after-background);
  }
`;

const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.sm}px;
`;

function AddYourPool() {
  return (
    <Container>
      <TextsWrapper>
        <Typography.H2>ADD YOUR POOL</Typography.H2>
        <Typography.Copy>Distribute tokens to your community</Typography.Copy>
      </TextsWrapper>
    </Container>
  );
}

export default memo(AddYourPool);
