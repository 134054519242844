import React, { memo } from 'react';
import styled from 'styled-components';

import Typography from '../../../../components/Atomic/Typography';
import EarnProgramsList from '../../../../components/Earn/EarnProgramsList';
import LearnQuizEarnCards from '../../../../components/Earn/LearnQuizEarnCards';
import MaxWidthContainer from '../../../../components/Layout/Containers/MaxWidthContainer';

import programs from '../../../../modules/Earn/seed.data';

const ScreenContainer = styled.div`
  display: flex;
`;

const ContentContainer = styled(MaxWidthContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.sizes.xxl * 3}px;
  --spacing-vertical: ${props => props.theme.sizes.xxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    --spacing-vertical: ${props => props.theme.sizes.xxl}px;
    gap: ${props => props.theme.sizes.xxl * 2}px;
  }

  margin-top: var(--spacing-vertical);
  margin-bottom: var(--spacing-vertical);
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.xl}px;

  @media all and ${({ theme: { device } }) => device.sm} {
    padding: 0;
  }
`;

const HeroSectionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.sm}px;
`;

const WhatCanIEarnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.xl}px;
`;

const WhatCanIEarnContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin: auto;
  max-width: 750px;
  text-align: center;
  gap: ${props => props.theme.sizes.sm}px;
`;

function EarnListScreen() {
  return (
    <ScreenContainer>
      <ContentContainer>
        <HeroSection>
          <HeroSectionTextWrapper>
            <Typography.H1>Earn Program</Typography.H1>
            <Typography.Copy size={'large'}>
              Acquire cryptoassets in a fun and low risk way, by taking lessons and testing your knowledge.
            </Typography.Copy>
          </HeroSectionTextWrapper>

          <LearnQuizEarnCards />
        </HeroSection>

        <WhatCanIEarnWrapper>
          <WhatCanIEarnContainer>
            <Typography.H2>What tokens can I earn?</Typography.H2>
            <Typography.Copy>
              ESDT Drops will partner with trusted & emerging projects to offer an easy way to learn about
              cryptocurrency, <b>earning cryptoassets as a reward</b>.
            </Typography.Copy>
          </WhatCanIEarnContainer>
          <EarnProgramsList programs={programs} />
        </WhatCanIEarnWrapper>
      </ContentContainer>
    </ScreenContainer>
  );
}

export default memo(EarnListScreen);
