import TokenModel from './TokenModel';
import { TOKEN_TYPE_NON_FUNGIBLE } from '../TokensContstants';

export default class NftTokenModel extends TokenModel {
  type = TOKEN_TYPE_NON_FUNGIBLE;

  creator = undefined;

  timestamp = undefined;
  attributes = undefined;
  isWhitelistedStorage = undefined;
  royalties = undefined;

  uris = [];
  url = undefined;
  thumbnailUrl = undefined;
  tags = [];

  constructor(params: any) {
    super(params);
    this.initializeModel(params);
  }
}
