import Environment from '../Environment';
import EsdtTokenModel from './models/EsdtTokenModel';

const ESDT_COUNT_ROUTE = '/tokens/esdt/pages-count';
const ESDT_ROUTE = '/tokens/esdt';

export default class TokensService {
  static API_URL = Environment.microservice.apiAddress;

  private static async getNumberOfEsdtTokensPages() {
    return fetch(`${TokensService.API_URL}${ESDT_COUNT_ROUTE}`)
      .then(response => response.json())
      .then(response => Math.max(1, response.numberOfPages));
  }

  static async getEsdtTokens() {
    const edtTokenRequest = (pageNumber: number) => {
      return fetch(`${TokensService.API_URL}${ESDT_ROUTE}?pageNumber=${pageNumber}`)
        .then(response => response.json())
        .then(items => items.map((item: any) => new EsdtTokenModel(item)));
    };

    const numberOfPages = await TokensService.getNumberOfEsdtTokensPages();

    const promises = [];
    for (let i = 1; i <= numberOfPages; i++) {
      promises.push(edtTokenRequest(i));
    }

    return (
      Promise.all([...promises])
        // eslint-disable-next-line prefer-spread
        .then(results => [].concat.apply([], results))
        .then(items => {
          return items;
        })
    );
  }
}
