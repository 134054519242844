import { TThemeColors, TThemeProps } from '../../types';
import DefaultCommonTheme from './default-common';

const LightTheme: Partial<TThemeProps> = {
  colors: {
    body: '#f7f9fd',
    text: '#121620',
    background: '#fff',
    border: '#d8d8d8',
    highlight: '#e3e3e3',
    overlay: 'rgba(0, 0, 0, 0.2)',
    shadow: 'rgba(0,0,0,.16)',

    buttonDisabledColor: '#a1a1a1',
  } as TThemeColors,
} as TThemeProps;

// const LightTheme: Partial<TThemeProps> = {
//   colors: {
//     body: '#f8f8f8',
//     text: '#0a0f17',
//     background: '#f4f7ff',
//     border: '#a0a8b2',
//     highlight: '#d2d2d2',
//     overlay: 'rgba(0, 0, 0, 0.2)',
//     shadow: 'rgba(0,0,0,.16)',
//   } as TThemeColors,
// } as TThemeProps;

const DefaultLightTheme = {
  ...DefaultCommonTheme,
  colors: {
    ...DefaultCommonTheme.colors,
    ...LightTheme.colors,
  },
};

export default DefaultLightTheme;
