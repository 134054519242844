import PoolModel from './models/pool.model';
import EAccessType from './enums/access.type.enum';

const pool = new PoolModel();
pool.accessType = EAccessType.ACCESS_TYPE_WHITELIST;
pool.participants = Math.ceil(Math.random() * 1500);
pool.maxNumberOfParticipants = 1000 + Math.ceil(Math.random() * 1500);
pool.tokenAmount = 1000000;
pool.remainingAmount = 753000;
pool.claimAmount = 7500;

const pools: PoolModel[] = [
  { ...pool, name: 'QWT Distribution', tokenIdentifier: 'QWT-46ac01' } as PoolModel,
  { ...pool, name: 'WAM Distribution', tokenIdentifier: 'WAM-510e42' } as PoolModel,
  { ...pool, name: 'LAND Distribution', tokenIdentifier: 'LAND-40f26f' } as PoolModel,
  { ...pool, name: 'ZPAY Distribution', tokenIdentifier: 'ZPAY-247875' } as PoolModel,
];

export default pools;
