import React, { memo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  font-size: 0.8em;
  gap: var(--spacing);
  justify-content: space-between;
  font-weight: 400;
`;

const TitleText = styled.div`
  color: #b7b7b7;
`;

const ValueText = styled.div`
  font-weight: bold;
`;

type Props = {
  title: string;
  value: string;
};

function PoolInfoItem(props: Props) {
  const { title, value } = props;

  return (
    <Container>
      <TitleText>{title}</TitleText>
      <ValueText>{value}</ValueText>
    </Container>
  );
}

export default memo(PoolInfoItem);
