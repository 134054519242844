import EarnProgramModel from './models/earn.program.model';
import LessonModel from './Lessons/models/lesson.model';
import EEarnProgramStatus from './enums/earn.program.status.enum';
import LessonButtonModel from './Lessons/models/lesson.button.model';

const watchVideoButton = new LessonButtonModel();
watchVideoButton.title = 'Watch Video';
watchVideoButton.url = 'https://esdt.market';

const visitWebsiteButton = new LessonButtonModel();
visitWebsiteButton.title = 'Visit Website';
visitWebsiteButton.url = 'https://esdt.market';

const viewWhitepaperButton = new LessonButtonModel();
viewWhitepaperButton.title = 'View Whitepaper';
viewWhitepaperButton.url = 'https://esdt.market';

const lesson = new LessonModel();
lesson.title = '';
lesson.content = [
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
];
lesson.buttons = [];

const lessons: LessonModel[] = [
  { ...lesson, buttons: [visitWebsiteButton], title: 'What is Lorem Ipsum?' } as LessonModel,
  { ...lesson, buttons: [watchVideoButton], title: 'Why do we use it?' } as LessonModel,
  { ...lesson, title: 'Where does it come from?' } as LessonModel,
  { ...lesson, buttons: [watchVideoButton, viewWhitepaperButton], title: 'Where can I get some?' } as LessonModel,
  { ...lesson, title: 'What is Lorem Ipsum?' } as LessonModel,
];

const program = new EarnProgramModel();
program.participants = Math.ceil(Math.random() * 25);
program.maxNumberOfParticipants = 150 + Math.ceil(Math.random() * 100);
program.earnAmount = 150;
program.tokenAmount = 100000;
program.remainingAmount = 25150;
program.lessons = lessons;
program.status = EEarnProgramStatus.ACTIVE;

program.heroParagraphs = [
  'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
];

const programs: EarnProgramModel[] = [
  { ...program, id: '1', name: 'QoWatt', tokenIdentifier: 'QWT-46ac01' } as EarnProgramModel,
  { ...program, id: '2', name: 'WAM', tokenIdentifier: 'WAM-510e42' } as EarnProgramModel,
  { ...program, id: '3', name: 'Landboard', tokenIdentifier: 'LAND-40f26f' } as EarnProgramModel,
  {
    ...program,
    id: '4',
    name: 'Upark',
    status: EEarnProgramStatus.ENDED,
    tokenIdentifier: 'UPARK-982dd6',
  } as EarnProgramModel,
  { ...program, id: '5', name: 'ZPAY', tokenIdentifier: 'ZPAY-247875' } as EarnProgramModel,
  { ...program, id: '6', name: 'Krogan', tokenIdentifier: 'KRO-df97ec' } as EarnProgramModel,
  { ...program, id: '7', name: 'BHNetwork', tokenIdentifier: 'BHAT-c1fde3' } as EarnProgramModel,
  { ...program, id: '8', name: 'VITAL', tokenIdentifier: 'VITAL-bc0917' } as EarnProgramModel,
];

export default programs;
