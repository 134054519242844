import { useContext, useMemo } from 'react';

import TokenModel from '../models/TokenModel';
import { EsdtTokensContext } from '../Esdt/EsdtTokensContextProvider';

export function useEsdtToken(tokenIdentifier: string | undefined): TokenModel {
  const { allTokensMap } = useContext(EsdtTokensContext);
  return useMemo(() => {
    if (tokenIdentifier && allTokensMap.hasOwnProperty(tokenIdentifier)) {
      return allTokensMap[tokenIdentifier];
    }

    return new TokenModel({});
  }, [allTokensMap, tokenIdentifier]);
}
