import styled from 'styled-components';

const MaxWidthContainer = styled.div`
  box-sizing: border-box;
  --padding-horizontal: ${props => props.theme.sizes.lg}px;

  padding-left: var(--padding-horizontal);
  padding-right: var(--padding-horizontal);

  @media all and ${({ theme: { device } }) => device.sm} {
    --padding-horizontal: ${props => props.theme.sizes.md}px;
  }

  margin: auto;
  width: 100vw;
  max-width: 1600px;
`;

export default MaxWidthContainer;
