import breakpoints from './breakpoints';
import { BreakpointFunctions } from '../types';

const minWidth = (breakpoint: string) => `(min-width: calc(${breakpoint} + 0.02px))`;
const maxWidth = (breakpoint: string) => `(max-width: ${breakpoint})`;

const breakpointFunctions: BreakpointFunctions = {
  up: (breakpoint: string) => minWidth(breakpoint),
  down: (breakpoint: string) => maxWidth(breakpoint),

  between: (breakpointMin: string, breakpointMax: string) =>
    `${minWidth(breakpointMin)} and ${maxWidth(breakpointMax)}`,

  only: (breakpoint: string) => {
    const keys = Object.keys(breakpoints);
    const keyIndex = Object.values(breakpoints).indexOf(breakpoint);
    const isLast = keyIndex === keys.length - 1;

    if (isLast) {
      return `${minWidth(breakpoint)}`;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `${minWidth(breakpoint)} and ${maxWidth(breakpoints[keys[keyIndex + 1]])}`;
    }
  },

  /**
   * Legacy function which targets screen sizes up to or equal to `breakpoint (xs | sm | md | lg | xl)`
   */
  device: (breakpoint: string) => {
    const keys = Object.keys(breakpoints);
    const keyIndex = keys.indexOf(breakpoint);
    const isLast = keyIndex === keys.length - 1;
    const breakpointKey = keys[keyIndex + 1];

    if (isLast) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `${maxWidth(breakpoints[breakpointKey])}`;
    } else {
      return `${minWidth(breakpoint)}`;
    }
  },
};

export default breakpointFunctions;
