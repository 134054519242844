import React, { memo } from 'react';
import styled from 'styled-components';

import Typography from '../../../Atomic/Typography';
import LessonModel from '../../../../modules/Earn/Lessons/models/lesson.model';
import DefaultButton from '../../../Atomic/Buttons/DefaultButton';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.md}px;

  border-radius: var(--default-border-radius);
  padding: ${props => props.theme.sizes.xxl}px;
  background: ${props => props.theme.colors.gradient};

  @media all and ${({ theme: { device } }) => device.sm} {
    padding: ${props => props.theme.sizes.lg}px;
  }
`;

const LessonNumber = styled(Typography.Copy)`
  align-self: flex-start;
  border-radius: var(--default-border-radius);
  padding: ${props => props.theme.sizes.xs}px ${props => props.theme.sizes.md}px;
  background-color: ${props => props.theme.colors.border};
`;

const ParagraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.md}px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.sizes.md}px;
`;

type Props = {
  lessonNumber: number;
  lesson: LessonModel;
};

function LessonItem(props: Props) {
  const { lesson, lessonNumber } = props;

  return (
    <Container>
      <LessonNumber size={'tiny'}>Lesson {lessonNumber}</LessonNumber>
      <Typography.H3>{lesson.title}</Typography.H3>

      <ParagraphsWrapper>
        {lesson.content.map((paragraph, index) => (
          <Typography.Copy key={index}>{paragraph}</Typography.Copy>
        ))}
      </ParagraphsWrapper>

      <ButtonsWrapper>
        {lesson.buttons.map((button, index) => (
          <DefaultButton key={index} type={index === 0 ? 'primary' : 'secondary'}>
            {button.title}
          </DefaultButton>
        ))}
      </ButtonsWrapper>
    </Container>
  );
}

export default memo(LessonItem);
