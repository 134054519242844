import _ from 'lodash';
import { TThemeColors, TThemeProps } from '../../types';
import GlobalThemeProps from '../../config/global-theme-props';

const colors = {
  primary: '#2B67E8',
  textOnPrimary: '#fff',
  secondary: '#ff9b00',
  textOnSecondary: '#fff',
  success: '#3bad62',
  pending: '#ff9b00',
  error: '#a10000',
} as TThemeColors;

const CommonPartialTheme = {
  colors: colors,
} as TThemeProps;

const CommonTheme: TThemeProps = _.merge(GlobalThemeProps, CommonPartialTheme);

export default CommonTheme;
