import sizes from './sizes';
import breakpointFunctions from './breakpoint-functions';
import deviceSizes from './device-sizes';

const GlobalThemeProps = {
  sizes: sizes,
  breakpointFunctions: breakpointFunctions,
  device: deviceSizes.deviceMedia,
};

export default GlobalThemeProps;
