const deviceMaxSizes = {
  xs: '600px',
  sm: '960px',
  md: '1280px',
  lg: '1920px',
};

const deviceMedia = {
  xs: `(max-width: ${deviceMaxSizes.xs})`,
  sm: `(max-width: ${deviceMaxSizes.sm})`,
  md: `(max-width: ${deviceMaxSizes.md})`,
  lg: `(max-width: ${deviceMaxSizes.lg})`,
};

export default {
  deviceMaxSizes,
  deviceMedia,
};
