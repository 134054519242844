import Environment from '../Environment';

export default class TokenPricesService {
  static API_URL = Environment.microservice.apiAddress;

  static async getTokenPrices() {
    return fetch(`${TokenPricesService.API_URL}/tokens/esdt/prices`)
      .then(response => response.json())
      .catch(() => []);
  }
}
