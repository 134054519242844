import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Icon from '../../Icon';

type TButtonIconProps = {
  name: string;
  size?: number;
};

type TButtonProps = {
  children?: React.ReactElement | string;
  text?: string;
  textOnSmallScreen?: string;
  buttonType?: string;
  // eslint-disable-next-line react/require-default-props
  iconProps?: TButtonIconProps;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  type?: string;
  mode?: string;
  to?: string;
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  --button-border-radius: var(--default-border-radius);
  --button-padding-size: ${props => props.theme.sizes.xs}px;

  --button-text-color: ${props => props.theme.colors.background};
  --button-border-color: ${props => props.theme.colors.text};
  --button-background-color: ${props => props.theme.colors.text};

  color: var(--button-text-color);
  padding: var(--button-padding-size);
  border: 1px solid var(--button-border-color);
  border-radius: var(--button-border-radius);
  background-color: var(--button-background-color);

  cursor: pointer;
  font-weight: 500;
  font-size: 0.8em;
  text-transform: uppercase;

  &[data-type='primary'] {
    --button-background-color: ${props => props.theme.colors.primary};
    --button-text-color: ${props => props.theme.colors.textOnPrimary};
    --button-border-color: ${props => props.theme.colors.primary};
  }

  &[data-type='secondary'] {
    --button-background-color: ${props => props.theme.colors.secondary};
    --button-text-color: ${props => props.theme.colors.textOnSecondary};
    --button-border-color: ${props => props.theme.colors.secondary};
  }

  &[data-mode='outline'] {
    background-color: transparent;
    color: var(--button-background-color);
  }

  &[data-mode='text'] {
    border-color: transparent;
    background-color: transparent;
    color: var(--button-background-color);
  }

  &[data-disabled='true'] {
    border-color: transparent;
    background-color: ${props => props.theme.colors.buttonDisabledColor};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.sizes.xs}px;
`;

function DefaultButton({
  children,
  text,
  textOnSmallScreen,
  iconProps,
  disabled,
  loading,
  onClick,
  type,
  mode,
  to,
  ...props
}: TButtonProps) {
  const navigate = useNavigate();
  const buttonHasOnClick = useMemo(() => typeof onClick === 'function', [onClick]);

  const buttonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) return false;

    if (buttonHasOnClick) {
      if (onClick) {
        onClick();
      }
    } else {
      if (to) {
        navigate(to);
      }
    }

    return false;
  };

  return (
    <ButtonContainer data-type={type} data-mode={mode} onClick={buttonClick} data-disabled={disabled} {...props}>
      <ContentContainer>
        <span>
          {children}
          {text}
          {textOnSmallScreen}
        </span>
        {!loading && iconProps && <Icon size={14} {...iconProps} />}
      </ContentContainer>
    </ButtonContainer>
  );
}

export default DefaultButton;
