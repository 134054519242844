import Environment from '../Environment';
import AccountModel from './AccountModel';

import NftTokenModel from '../Tokens/models/NftTokenModel';
import EsdtTokenModel from '../Tokens/models/EsdtTokenModel';
import { TOKEN_TYPE_META_ESDT } from '../Tokens/TokensContstants';

const API_ADDRESS = Environment.microservice.apiAddress;

const SINGLE_ESDT_TOKEN_ROUTE = '/accounts/token/esdt';
const ESDT_TOKENS_ROUTE = '/accounts/tokens/esdt';
const ESDT_TOKENS_PAGES_COUNT_ROUTE = '/accounts/tokens/esdt/pages-count';
const META_TOKENS_ROUTE = '/accounts/tokens/nft';
const META_TOKENS_PAGES_COUNT_ROUTE = '/accounts/tokens/nft/pages-count';

export default class AccountService {
  static filtersToString = (filters: any) =>
    Object.entries(filters)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

  static getInformation(walletAddress: string) {
    if (!walletAddress) {
      return Promise.resolve({});
    }

    return fetch(`${API_ADDRESS}/accounts/information?address=${walletAddress}`)
      .then(response => response.json())
      .catch(() => Promise.resolve({}));
  }

  static async getNumberOfEsdtTokensPages(address: string) {
    return fetch(`${API_ADDRESS}${ESDT_TOKENS_PAGES_COUNT_ROUTE}?address=${address}`)
      .then(response => response.json())
      .then(response => response.numberOfPages)
      .catch(() => 0);
  }

  static async getNumberOfMetaTokensPages(address: string) {
    return fetch(`${API_ADDRESS}${META_TOKENS_PAGES_COUNT_ROUTE}?address=${address}&type=${TOKEN_TYPE_META_ESDT}`)
      .then(response => response.json())
      .then(response => response.numberOfPages);
  }

  static async getEsdtToken(address: string, identifier: string) {
    if (!address) {
      return Promise.reject();
    }

    return fetch(`${API_ADDRESS}${SINGLE_ESDT_TOKEN_ROUTE}&address=${address}&identifier=${identifier}`)
      .then(response => response.json())
      .then(response => this.itemToModel(response, EsdtTokenModel));
  }

  static async getEsdtTokens(address: string) {
    if (!address) {
      return Promise.resolve([]);
    }

    const pageRequestCallback = (pageNumber: number) => {
      return fetch(`${API_ADDRESS}${ESDT_TOKENS_ROUTE}?pageNumber=${pageNumber}&address=${address}`)
        .then(response => response.json())
        .then(response => this.arrayToModels(response, EsdtTokenModel))
        .catch(() => []);
    };

    const numberOfPages = await this.getNumberOfEsdtTokensPages(address);
    return this.getTokensWithPagination(numberOfPages, pageRequestCallback);
  }

  static async getMetaEsdtTokens(address: string) {
    if (!address) {
      return Promise.resolve([]);
    }

    const pageRequestCallback = (pageNumber: number) => {
      return fetch(
        `${API_ADDRESS}${META_TOKENS_ROUTE}?pageNumber=${pageNumber}&address=${address}&type=${TOKEN_TYPE_META_ESDT}`,
      )
        .then(response => response.json())
        .then(response => this.arrayToModels(response, NftTokenModel))
        .catch(() => []);
    };

    const numberOfPages = await this.getNumberOfMetaTokensPages(address);
    return this.getTokensWithPagination(numberOfPages, pageRequestCallback);
  }

  static async getTokensWithPagination(numberOfPages: number, callback: (pageNumber: number) => Promise<any>) {
    const promises = [];
    for (let i = 1; i <= numberOfPages; i++) {
      promises.push(callback(i));
    }

    // eslint-disable-next-line prefer-spread
    return Promise.all([...promises]).then(results => [].concat.apply([], results));
  }

  static arrayToModels = (data: any, model: any) => data.map((item: any) => this.itemToModel(item, model));
  static itemToModel = (item: any, model: any) => new model(item);
}
