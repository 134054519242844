import React, { memo } from 'react';
import styled from 'styled-components';

import Typography from '../../Atomic/Typography';
import { TokenIcon } from '../../Tokens/TokenIcon';
import DefaultButton from '../../Atomic/Buttons/DefaultButton';

import PoolModel from '../../../modules/Pools/models/pool.model';
import { useEsdtToken } from '../../../modules/Tokens/hooks/useTokens';
import EAccessType from '../../../modules/Pools/enums/access.type.enum';

import PoolInfo from './PoolInfo';

const Container = styled.div`
  --color: ${props => props.theme.colors.primary};
  --bg-color: ${props => props.theme.colors.border};
  --spacing: ${props => props.theme.sizes.md}px;

  background-color: ${props => props.theme.colors.background};

  flex: 1;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--bg-color);
  border-radius: var(--default-border-radius);
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${props => props.theme.sizes.xs}px;
  padding: var(--spacing);
  background-color: var(--bg-color);
  font-weight: bold;
  text-transform: uppercase;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: calc(var(--spacing) * 2) var(--spacing);
  font-weight: bold;
  text-transform: uppercase;
  gap: var(--spacing);
`;

const FooterContainer = styled.div`
  display: flex;
  padding: var(--spacing);
  gap: var(--spacing);
`;

const StatsContainer = styled.div`
  flex-grow: 1;
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: max-content max-content;
  column-gap: ${props => props.theme.sizes.xxs}px;
  row-gap: ${props => props.theme.sizes.xxs}px;

  font-size: 0.85em;

  @media all and ${({ theme: { device } }) => device.xs} {
    flex-direction: column;
    align-items: flex-start;
    grid-template-columns: 1fr;
  }
`;

const ParticipantsContainer = styled.div`
  display: flex;
  align-items: center;

  grid-row-start: 1;
  grid-row-end: 3;
  grid-column: 2;

  @media all and ${({ theme: { device } }) => device.xs} {
    grid-column: 1;
    grid-row-start: 3;
  }
`;

type Props = {
  pool: PoolModel;
};

function PoolItem(props: Props) {
  const { pool } = props;
  const token = useEsdtToken(pool.tokenIdentifier);

  return (
    <Container>
      <HeaderContainer>
        <TokenIcon token={token} />
        <Typography.Copy size={'large'}>{pool.name}</Typography.Copy>
      </HeaderContainer>

      <ContentContainer>
        <PoolInfo pool={pool} />
      </ContentContainer>

      <FooterContainer>
        <StatsContainer>
          <div>ACCESS TYPE: {pool.accessType}</div>

          <ParticipantsContainer>
            {`PARTICIPANTS: ${pool.participants.toLocaleString()}`}
            {pool.maxNumberOfParticipants && ` / ${pool.maxNumberOfParticipants.toLocaleString()}`}
          </ParticipantsContainer>

          {pool.accessType === EAccessType.ACCESS_TYPE_WHITELIST && (
            <div>SELF-WHITELIST: {pool.canSelfWhitelist ? 'ENABLED' : 'DISABLED'}</div>
          )}
        </StatsContainer>
        <DefaultButton mode={'outline'}>SUBSCRIBE</DefaultButton>
      </FooterContainer>
    </Container>
  );
}

export default memo(PoolItem);
