import React, { useMemo } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useScrollToTopHandler } from './hooks/useScrollToTopHandler';

import PoolsListScreen from '../../pages/App/Pools/PoolsListScreen';
import EarnListScreen from '../../pages/App/Earn/EarnListScreen';
import EarnProgramScreen from '../../pages/App/Earn/EarnProgramScreen';
import ExclusivePoolsListScreen from '../../pages/App/Pools/ExclusivePoolsListScreen';

const NotFoundScreen = React.lazy(() => import('../../pages/Landing/NotFoundScreen'));
const AuthButtonsScreen = React.lazy(() => import('../../pages/Auth/AuthButtonsScreen'));

const AppLayout = React.lazy(() => import('../../components/Layout/App'));
const AuthModalLayout = React.lazy(() => import('../../components/Layout/AuthModal'));

// const PoolsListScreen = React.lazy(() => import('../../pages/App/Pools/PoolsListScreen'));

// const EarnListScreen = React.lazy(() => import('../../pages/App/Earn/EarnListScreen'));
// const EarnProgramScreen = React.lazy(() => import('../../pages/App/Earn/EarnProgramScreen'));

function EmptyComponent() {
  return <>Empty</>;
}

function ApplicationRouter() {
  const location = useLocation();
  const state = useMemo(() => location.state as { backgroundLocation?: Location }, [location]);
  useScrollToTopHandler();

  return (
    <React.Suspense fallback={<></>}>
      <Routes location={state?.backgroundLocation || location}>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<PoolsListScreen />} />
          <Route path="about" element={<EmptyComponent />} />
          <Route path="team" element={<EmptyComponent />} />
          <Route path="faq" element={<EmptyComponent />} />
        </Route>

        <Route path="pools" element={<AppLayout />}>
          <Route index element={<PoolsListScreen />} />
          <Route path={'exclusive'} element={<ExclusivePoolsListScreen />} />
          <Route path={'create'} element={<PoolsListScreen />} />
        </Route>

        <Route path="earn" element={<AppLayout />}>
          <Route index element={<EarnListScreen />} />
          <Route path={':programId'} element={<EarnProgramScreen />} />
        </Route>

        <Route path="*" element={<NotFoundScreen />} />
      </Routes>

      <React.Suspense fallback={<></>}>
        <Routes>
          <Route path="/app/auth" element={<AuthModalLayout />}>
            <Route index element={<AuthButtonsScreen />} />
          </Route>
        </Routes>
      </React.Suspense>
    </React.Suspense>
  );
}

export default ApplicationRouter;
