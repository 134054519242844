import { useMemo } from 'react';

import { EgldToken, LockedMexToken } from '../CustomTokens';

export const useTokenPriceListDecorator = (tokenPrices: any[]) => {
  return useMemo(() => {
    const tokenPricesMap: any = {};
    const tokenPricesList: any = [];

    tokenPrices.forEach(item => {
      tokenPricesList.push(item);
      tokenPricesMap[item.identifier] = item;

      const ticker = item.identifier.split('-')[0];
      switch (true) {
        case ticker === 'WEGLD':
          const egldTokenPrice = { ...item, identifier: EgldToken.identifier };
          tokenPricesMap[EgldToken.identifier] = egldTokenPrice;
          tokenPricesList.push(egldTokenPrice);
          break;
        case ticker === 'MEX':
          const lockedMexTokenPrice = { ...item, identifier: LockedMexToken.identifier };
          tokenPricesMap[LockedMexToken.identifier] = lockedMexTokenPrice;
          tokenPricesList.push(lockedMexTokenPrice);
          break;
        default:
          break;
      }
    });

    return {
      tokenPricesList,
      tokenPricesMap,
    };
  }, [tokenPrices]);
};
