import { EnvironmentsEnum } from '@elrondnetwork/dapp-core/types/enums';
import { TEnvironmentConfig } from '../types';

import CommonConfig from '../envs/common.json';
import ProductionConfig from '../envs/mainnet.json';
import StagingConfig from '../envs/testnet.json';

const ENV_FILES_BY_BUILD_TYPE = {
  [EnvironmentsEnum.mainnet]: ProductionConfig,
  [EnvironmentsEnum.testnet]: StagingConfig,
  [EnvironmentsEnum.devnet]: StagingConfig,
};

export default function getBuildTypeEnvironmentVariables(buildType: EnvironmentsEnum): TEnvironmentConfig {
  return {
    IS_PRODUCTION_ENVIRONMENT: buildType === EnvironmentsEnum.mainnet,
    IS_STAGING_ENVIRONMENT: buildType === EnvironmentsEnum.testnet,
    ...CommonConfig,
    ...ENV_FILES_BY_BUILD_TYPE[buildType as EnvironmentsEnum],
  };
}
