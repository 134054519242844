const sizes = {
  xxs: 5,
  xs: 10,
  sm: 15,
  md: 20,
  lg: 30,
  xl: 40,
  xxl: 60,
  xxxl: 100,
};

export default sizes;
