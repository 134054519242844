import React from 'react';
import styled from 'styled-components';

import TokenModel from '../../../modules/Tokens/models/TokenModel';

export const TokenImage = styled.div<{ src: string | null; size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  padding: ${props => props.theme.sizes.xxs}px;

  background-image: url(${props => props.src});
  background-size: contain;
  background-position: center left;
  background-repeat: no-repeat;
`;

export const TokenIcon = ({ token, size = 24 }: { token: TokenModel; size?: number }) => {
  if (!token.hasAssetImage()) {
    return <></>;
  }

  return <TokenImage size={size} src={token.getAssetImageUrl()} />;
};
