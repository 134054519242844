import BigNumber from 'bignumber.js';

import Environment from '../../Environment';
import SerializableModel from '../../Core/models/serializable.model';

import {
  TOKEN_TYPE_ESDT,
  TOKEN_TYPE_META_ESDT,
  TOKEN_TYPE_NON_FUNGIBLE,
  TOKEN_TYPE_SEMI_FUNGIBLE,
} from '../TokensContstants';

export default class TokenModel extends SerializableModel {
  type = '';

  name = '';
  identifier = '';
  collection = '';
  ticker = '';
  nonce = '';
  decimals = 18;

  balance = '';

  assets = {
    website: undefined,
    svgUrl: undefined,
    pngUrl: undefined,
  };

  constructor(params: any) {
    super();
    this.initializeModel(params);
  }

  isEsdt = () => this.type === TOKEN_TYPE_ESDT;
  isMetaEsdt = () => this.type === TOKEN_TYPE_META_ESDT;
  isSemiFungible = () => this.type === TOKEN_TYPE_SEMI_FUNGIBLE;
  isNonFungible = () => this.type === TOKEN_TYPE_NON_FUNGIBLE;
  isNft = () => this.isNonFungible() || this.isSemiFungible();

  isLkMex = () => this.collection === Environment.tokens.special.LKMEX.collectionName;
  isMex = () => this.identifier === Environment.tokens.special.MEX.identifier;
  isEgld = () => this.identifier === Environment.tokens.special.EGLD.identifier;

  toBigUIntValue = (value: string | number): BigNumber =>
    new BigNumber(value).multipliedBy(Math.pow(10, this.decimals));
  fromBigUIntValue = (value: string): BigNumber => new BigNumber(value).dividedBy(Math.pow(10, this.decimals));

  getDenominatedBalance = () => this.fromBigUIntValue(this.balance);

  isVerified = () => {
    switch (true) {
      case this.hasAssetImage():
        return true;
      case this.isEsdt():
        return Environment.tokens.verified.esdt.includes(this.identifier);
      case this.isNft() || this.isMetaEsdt():
        return Environment.tokens.verified.nft.includes(this.collection);
      default:
        return false;
    }
  };

  hasAssetImage = () => this.assets.pngUrl !== undefined || this.assets.svgUrl !== undefined;

  getAssetImageUrl = (): string | null => {
    if (this.assets.svgUrl) {
      return this.assets.svgUrl;
    }

    if (this.assets.pngUrl) {
      return this.assets.pngUrl;
    }

    return null;
  };
}
