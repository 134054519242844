import SerializableModel from '../../Core/models/serializable.model';
import EAccessType from '../enums/access.type.enum';

export default class PoolModel extends SerializableModel {
  id: string;
  name: string;
  tokenIdentifier: string;
  tokenAmount: number;
  remainingAmount: number;

  claimAmount: number;

  accessType: EAccessType;
  canSelfWhitelist: boolean;

  participants: number;
  maxNumberOfParticipants: number;
}
