export default class AccountModel {
  public address: string = '';
  public heroTag: string = '';

  public egldBalance = '0';

  constructor() {
    /* not empty */
  }

  public withProviderData(providerData: any) {
    this.address = providerData.address || '';

    return this;
  }

  public withAccountInformation(accountInformation: any) {
    this.egldBalance = accountInformation.balance;
    this.computeHeroTag(accountInformation);

    return this;
  }

  private computeHeroTag(accountInformation: any) {
    if (accountInformation.username) {
      this.heroTag = `@` + accountInformation.username.split('.elrond')[0];
    } else {
      this.heroTag = this.address.slice(0, 5) + '...' + this.address.slice(-5);
    }
  }
}
