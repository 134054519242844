import React, { memo } from 'react';
import styled from 'styled-components';

import PoolModel from '../../../modules/Pools/models/pool.model';

import PoolItem from '../PoolItem';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.lg}px;
`;

type Props = {
  pools: PoolModel[];
};

function PoolsList(props: Props) {
  const { pools } = props;

  return (
    <Container>
      {pools.map(pool => (
        <PoolItem key={pool.id} pool={pool} />
      ))}
    </Container>
  );
}

export default memo(PoolsList);
