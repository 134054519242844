export default class SerializableModel {
  initializeModel(params: any) {
    const propertyKeys = typeof params === 'object' && params ? Object.keys(params) : [];

    propertyKeys.forEach((key: string) => {
      if (this.hasOwnProperty(key)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this[key] = params[key];
      }
    });
  }

  /*
   * @returns {string}
   */
  serialize() {
    return JSON.parse(JSON.stringify(this));
  }
}
