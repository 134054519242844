import React, { memo } from 'react';
import styled from 'styled-components';

import Icon from '../../Atomic/Icon';
import Typography from '../../Atomic/Typography';

const HowItWorksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.sm}px;
`;

const HowItWorksItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${props => props.theme.sizes.md}px;

  @media all and ${({ theme: { device } }) => device.sm} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const HowItWorksItemContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.sizes.md}px;
  align-items: center;
  border-radius: var(--default-border-radius);
  padding: ${props => props.theme.sizes.lg}px;
  background: ${props => props.theme.colors.gradient};

  ${Typography.H4} {
    font-weight: 600;
  }

  &[data-type='learn'] {
    color: ${props => props.theme.colors.primary};
  }

  &[data-type='quiz'] {
    color: ${props => props.theme.colors.secondary};
  }

  &[data-type='earn'] {
    color: ${props => props.theme.colors.success};
  }
`;

const HowItWorksItemContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.xxs}px;
`;

function LearnQuizEarnCards() {
  return (
    <HowItWorksContainer>
      <HowItWorksItemsWrapper>
        <HowItWorksItemContainer data-type={'learn'}>
          <Icon size={60} name={'school'} />
          <HowItWorksItemContentContainer>
            <Typography.H4>Learn</Typography.H4>
            <Typography.Copy>Learn about top & emerging crypto projects</Typography.Copy>
          </HowItWorksItemContentContainer>
        </HowItWorksItemContainer>

        <HowItWorksItemContainer data-type={'quiz'}>
          <Icon size={60} name={'history_edu'} />
          <HowItWorksItemContentContainer>
            <Typography.H4>Complete Quiz</Typography.H4>
            <Typography.Copy>Put your newfound knowledge to the test</Typography.Copy>
          </HowItWorksItemContentContainer>
        </HowItWorksItemContainer>

        <HowItWorksItemContainer data-type={'earn'}>
          <Icon size={60} name={'military_tech'} />
          <HowItWorksItemContentContainer>
            <Typography.H4>Earn Crypto</Typography.H4>
            <Typography.Copy>Get rewarded in the crypto you learned about!</Typography.Copy>
          </HowItWorksItemContentContainer>
        </HowItWorksItemContainer>
      </HowItWorksItemsWrapper>
    </HowItWorksContainer>
  );
}

export default memo(LearnQuizEarnCards);
