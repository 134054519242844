import React, { memo } from 'react';
import styled from 'styled-components';

import LessonItem from '../LessonItem';
import LessonModel from '../../../../modules/Earn/Lessons/models/lesson.model';

const Container = styled.div`
  flex: 1;
  display: grid;
  gap: ${props => props.theme.sizes.lg}px;
`;

type Props = {
  lessons: LessonModel[];
};

function LessonsList(props: Props) {
  const { lessons } = props;

  return (
    <Container>
      {lessons.map((lesson, index) => (
        <LessonItem key={lesson.id} lesson={lesson} lessonNumber={index + 1} />
      ))}
    </Container>
  );
}

export default memo(LessonsList);
