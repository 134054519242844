import React, { memo } from 'react';
import styled from 'styled-components';

import PoolModel from '../../../../modules/Pools/models/pool.model';
import PoolInfoItem from './PoolInfoItem';
import { useEsdtToken } from '../../../../modules/Tokens/hooks/useTokens';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing);

  @media all and ${({ theme: { device } }) => device.sm} {
    flex-direction: column;
  }
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--spacing);
  padding: var(--spacing);

  background: ${props => props.theme.colors.gradient};
  border-radius: var(--default-border-radius);

  &[data-type='token'] {
    color: ${props => props.theme.colors.primary};
  }

  &[data-type='claim'] {
    color: ${props => props.theme.colors.secondary};
  }

  &[data-type='distribution'] {
    color: ${props => props.theme.colors.success};
  }
`;

const SectionItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.xxs}px;
`;

type Props = {
  pool: PoolModel;
};

function PoolInfo(props: Props) {
  const { pool } = props;
  const token = useEsdtToken(pool.tokenIdentifier);

  return (
    <Container>
      <Section data-type={'token'}>
        <div data-role={'title'}>Token Information</div>

        <SectionItemsWrapper>
          <PoolInfoItem title={'Token'} value={pool.tokenIdentifier} />
          <PoolInfoItem title={'Total amount'} value={`${pool.tokenAmount.toLocaleString()} ${token.ticker}`} />
          <PoolInfoItem title={'Remaining amount'} value={`${pool.remainingAmount.toLocaleString()} ${token.ticker}`} />
        </SectionItemsWrapper>
      </Section>

      <Section data-type={'distribution'}>
        <div data-role={'title'}>Distribution</div>

        <SectionItemsWrapper>
          <PoolInfoItem title={'Period'} value={'1 YEAR'} />
          <PoolInfoItem title={'Claim Period'} value={`MONTHLY`} />
          <PoolInfoItem title={'Claim amount'} value={`${pool.claimAmount.toLocaleString()} ${token.ticker}`} />
        </SectionItemsWrapper>
      </Section>

      <Section data-type={'claim'}>
        <div data-role={'title'}>Claim</div>

        <SectionItemsWrapper>
          <PoolInfoItem title={'Claimable'} value={`7500 ${token.ticker}`} />
          <PoolInfoItem title={'Next claim in'} value={`1 DAY`} />
          <PoolInfoItem title={'Claimed so far'} value={`0 ${token.ticker}`} />
        </SectionItemsWrapper>
      </Section>
    </Container>
  );
}

export default memo(PoolInfo);
