import React, { memo } from 'react';
import styled from 'styled-components';

import EarnProgramItem from '../EarnProgramItem';
import EarnProgramModel from '../../../modules/Earn/models/earn.program.model';

const Container = styled.div`
  --grid-size: 4;

  flex: 1;
  display: grid;
  grid-template-columns: repeat(var(--grid-size), 1fr);
  gap: ${props => props.theme.sizes.lg}px;

  @media all and ${({ theme: { device } }) => device.md} {
    --grid-size: 3;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    --grid-size: 2;
  }

  @media all and ${({ theme: { device } }) => device.xs} {
    --grid-size: 1;
  }
`;

type Props = {
  programs: EarnProgramModel[];
};

function PoolsList(props: Props) {
  const { programs } = props;

  return (
    <Container>
      {programs.map(program => (
        <EarnProgramItem key={program.id} program={program} />
      ))}
    </Container>
  );
}

export default memo(PoolsList);
