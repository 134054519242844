import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import Typography from '../../../../components/Atomic/Typography';
import LessonsList from '../../../../components/Earn/Lessons/LessonsList';
import LearnQuizEarnCards from '../../../../components/Earn/LearnQuizEarnCards';
import MaxWidthContainer from '../../../../components/Layout/Containers/MaxWidthContainer';

import programs from '../../../../modules/Earn/seed.data';
import { useEsdtToken } from '../../../../modules/Tokens/hooks/useTokens';
import { EARN_LIST_ROUTE } from '../../../../modules/Navigation/NavigationConstants';

const ScreenContainer = styled.div`
  display: flex;
`;

const ContentContainer = styled(MaxWidthContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.sizes.xxl * 3}px;
  --spacing-vertical: ${props => props.theme.sizes.xxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    --spacing-vertical: ${props => props.theme.sizes.xxl}px;
  }

  margin-top: var(--spacing-vertical);
  margin-bottom: var(--spacing-vertical);
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.lg}px;

  @media all and ${({ theme: { device } }) => device.sm} {
    padding: 0;
  }
`;

const HeroSectionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.sm}px;
`;

const HeroParagraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.sm}px;

  max-width: 800px;
`;

const LessonsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin: auto;
  max-width: 1200px;
`;

const LessonsSectionTitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.sizes.xs}px;
  padding: ${props => props.theme.sizes.md}px;
  background-color: ${props => props.theme.colors.background};
  border-top-left-radius: calc(var(--default-border-radius) * 3);
  border-top-right-radius: calc(var(--default-border-radius) * 3);

  --spacing-size: 250px;

  margin: 0 var(--spacing-size);
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;

  @media all and ${({ theme: { device } }) => device.md} {
    --spacing-size: 100px;
  }

  @media all and ${({ theme: { device } }) => device.sm} {
    --spacing-size: 30px;
  }
`;

function EarnProgramScreen() {
  const { programId } = useParams();
  const navigate = useNavigate();

  const program = useMemo(() => (programs || []).find(program => program.id === programId), [programId]);
  const token = useEsdtToken(program?.tokenIdentifier);

  if (!program) {
    navigate(EARN_LIST_ROUTE);
    return null;
  }

  return (
    <ScreenContainer>
      <ContentContainer>
        <HeroSection>
          <HeroSectionTextWrapper>
            <Typography.H1>Learn & Earn</Typography.H1>
            <Typography.Copy size={'large'}>
              Learn about <b>{program.name}</b>, complete the quiz and earn{' '}
              <b>
                {program.earnAmount.toLocaleString()} {token.ticker}
              </b>{' '}
              tokens
            </Typography.Copy>
          </HeroSectionTextWrapper>

          <HeroParagraphsWrapper>
            {program.heroParagraphs.map((paragraph, index) => (
              <Typography.Copy key={index} size={'large'}>
                {paragraph}
              </Typography.Copy>
            ))}
          </HeroParagraphsWrapper>
          <LearnQuizEarnCards />
        </HeroSection>

        <LessonsSectionWrapper>
          <LessonsSectionTitleContainer>
            <Typography.H3>Lessons</Typography.H3>
          </LessonsSectionTitleContainer>

          <LessonsList lessons={program.lessons} />
        </LessonsSectionWrapper>
      </ContentContainer>
    </ScreenContainer>
  );
}

export default memo(EarnProgramScreen);
