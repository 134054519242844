import React from 'react';
import styled from 'styled-components';

type StyledIconProps = {
  size: number;
};

const StyledIcon = styled.div<StyledIconProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;

  font-size: ${props => props.size}px;
  line-height: 1;

  display: block;

  color: inherit;

  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';

  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
`;

type IconProps = {
  name: string;
  size?: number;
  title?: string;
};

function Icon({ name, size = 24, ...rest }: IconProps) {
  return (
    <StyledIcon size={size} {...rest}>
      {name}
    </StyledIcon>
  );
}

export default Icon;
