import React from 'react';
import { DappProvider as DappCoreProvider } from '@elrondnetwork/dapp-core/wrappers';

import Environment from '../Environment';
import { AccountProvider } from '../Account/AccountContextProvider';

export function DappProvider({ children }: { children: React.ReactElement }) {
  return (
    <DappCoreProvider
      environment={Environment.environmentType}
      customNetworkConfig={{ name: 'customConfig', apiTimeout: 60000 }}
    >
      <AccountProvider>{children}</AccountProvider>
    </DappCoreProvider>
  );
}
