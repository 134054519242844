import { TThemeColors, TThemeProps } from '../../types';
import DefaultCommonTheme from './default-common';

const DarkTheme: Partial<TThemeProps> = {
  colors: {
    body: '#1D2129',
    background: '#0e0f15',
    text: '#f1f1f1',
    border: '#39404a',
    highlight: '#4f4e4e',
    overlay: 'rgba(255, 255, 255, 0.2)',
    shadow: 'rgba(0,0,0,.25)',

    gradient: 'linear-gradient(45deg, #2d2f32, #1f283d)',

    buttonDisabledColor: '#4f4e4e',
  } as TThemeColors,
};

const DefaultDarkTheme = {
  ...DefaultCommonTheme,
  colors: {
    ...DefaultCommonTheme.colors,
    ...DarkTheme.colors,
  },
};

export default DefaultDarkTheme;
