import React, { memo } from 'react';
import styled from 'styled-components';

import Typography from '../../../Atomic/Typography';

const Container = styled.div`
  --color: #bf953f;
  --bg-color: ${props => props.theme.colors.gradient};
  --spacing: ${props => props.theme.sizes.md}px;
  --golden-gradient: linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);

  display: flex;
  position: relative;
  flex-direction: column;
  border-width: 2px;
  border-style: solid;
  border-color: var(--color);
  background: var(--bg-color);
  border-radius: calc(var(--default-border-radius) * 2);
  padding: ${props => props.theme.sizes.xxl * 3}px ${props => props.theme.sizes.xxl}px;

  @media all and ${({ theme: { device } }) => device.sm} {
    padding: ${props => props.theme.sizes.xxl * 2}px ${props => props.theme.sizes.xxl}px;
  }

  font-weight: bold;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 100%;

    clip-path: url(#curvedPath);
    background: var(--golden-gradient);
  }
`;

const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.sm}px;
`;

function EmptyExclusivePools() {
  return (
    <Container>
      <svg width="0" height="0">
        <defs>
          <clipPath id="curvedPath" clipPathUnits="objectBoundingBox">
            <path
              d="M 0,1
                  L 1,1
									C .65 .65, .35 .65, 0 1
									Z"
            />
          </clipPath>
        </defs>
      </svg>
      <TextsWrapper>
        <Typography.H1>VIP</Typography.H1>
        <Typography.H4>There are no exclusive pools at this time</Typography.H4>
      </TextsWrapper>
    </Container>
  );
}

export default memo(EmptyExclusivePools);
