import EarnQuestionModel from './earn.question.model';
import EEarnProgramStatus from '../enums/earn.program.status.enum';
import SerializableModel from '../../Core/models/serializable.model';
import LessonModel from '../Lessons/models/lesson.model';

export default class EarnProgramModel extends SerializableModel {
  id: string;
  name: string;
  tokenIdentifier: string;
  tokenAmount: number;
  remainingAmount: number;

  earnAmount: number;

  participants: number;
  maxNumberOfParticipants: number;

  status: EEarnProgramStatus;

  heroParagraphs: string[];

  lessons: LessonModel[];
  questions: EarnQuestionModel[];

  public getStatus() {
    return this.status;
  }
}
