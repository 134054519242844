import React, { createContext, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { GlobalStyles } from './config/global-styles';
import { DefaultDarkTheme, DefaultLightTheme } from './themes';

export const ThemeContext = createContext({
  mode: 'light',
  isDarkTheme: false,
  isLightTheme: true,
  switchTheme: () => {
    /*do nothing*/
  },
});

// eslint-disable-next-line react/display-name,react/prop-types
export const ThemeProvider: React.FC<{ children: React.ReactNode }> = memo(({ children }) => {
  const [mode, setMode] = useState('light');
  const isDarkTheme = useMemo(() => mode === 'dark', [mode]);
  const isLightTheme = useMemo(() => mode === 'light', [mode]);

  const theme = useMemo(() => (isDarkTheme ? DefaultDarkTheme : DefaultDarkTheme), [isDarkTheme]);

  useEffect(() => {
    const localStorageMode = window.localStorage.getItem('prefers-color-scheme');

    if (localStorageMode) {
      setMode(localStorageMode);
      return;
    }

    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    }

    return () => {
      /** do nothing **/
    };
  }, []);

  useEffect(() => {
    const callback = (event: { matches: boolean }) => {
      const newColorScheme = event.matches ? 'dark' : 'light';
      setMode(newColorScheme);
    };

    if (window.matchMedia) {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', callback);
    }

    return () => {
      /** do nothing **/
    };
  }, [setMode]);

  const switchTheme = useCallback(() => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    window.localStorage.setItem('prefers-color-scheme', newMode);
    setMode(newMode);
  }, [setMode, mode]);

  return (
    <ThemeContext.Provider value={{ mode, switchTheme, isDarkTheme, isLightTheme }}>
      <StyledComponentsThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </StyledComponentsThemeProvider>
    </ThemeContext.Provider>
  );
});
