import React, { createContext, useEffect, useState } from 'react';

import TokensService from '../TokensService';
import useInterval from '../../Helpers/useInterval';
import TokenPricesService from '../TokenPricesService';

import { EsdtTokensList, EsdtTokensMap, TTokenListDecorator, TTokenPriceListDecorator } from '../types';
import { EgldToken, LockedMexToken } from '../CustomTokens';
import useTokenListDecorator from '../hooks/useTokenListDecorator';
import { useTokenPriceListDecorator } from '../hooks/useTokenPriceListDecorator';

type EsdtTokensContextReturnType = {
  esdtTokens: EsdtTokensList;
  allTokensMap: EsdtTokensMap;
  verifiedEsdtTokens: EsdtTokensList;
  verifiedTokensMap: EsdtTokensMap;
  prices: TTokenPriceListDecorator;
};

export const EsdtTokensContext = createContext<EsdtTokensContextReturnType>({} as EsdtTokensContextReturnType);
export const EsdtTokensProvider = ({ children }: { children: JSX.Element }) => {
  const [tokenPrices, setTokenPrices] = useState<any>([]);
  const [esdtTokens, setEsdtTokens] = useState<EsdtTokensList>([]);
  const tokens: TTokenListDecorator = useTokenListDecorator(esdtTokens);
  const prices: TTokenPriceListDecorator = useTokenPriceListDecorator(tokenPrices);

  useEffect(() => {
    TokenPricesService.getTokenPrices().then(setTokenPrices);
    TokensService.getEsdtTokens().then(items => {
      if (items.length) {
        const allTokens = [EgldToken, LockedMexToken, ...items];
        setEsdtTokens(allTokens);
      }
    });

    return () => {
      /** do nothing **/
    };
  }, []);

  useInterval(
    () =>
      TokensService.getEsdtTokens().then(items => {
        if (items.length) {
          const allTokens = [EgldToken, LockedMexToken, ...items];
          setEsdtTokens(allTokens);
        }
      }),
    60000,
  );

  return (
    <EsdtTokensContext.Provider
      value={{
        esdtTokens: tokens.allTokensList,
        allTokensMap: tokens.allTokensMap,
        verifiedEsdtTokens: tokens.verifiedTokensList,
        verifiedTokensMap: tokens.verifiedTokensMap,
        prices,
      }}
    >
      {children}
    </EsdtTokensContext.Provider>
  );
};
