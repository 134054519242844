import { getIsLoggedIn } from '@elrondnetwork/dapp-core/utils';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useGetAccountInfo, useGetAccountProvider } from '@elrondnetwork/dapp-core/hooks';

import AccountModel from './AccountModel';
import AccountService from './AccountService';
import useInterval from '../Helpers/useInterval';
import { TTokenListDecorator } from '../Tokens/types';
import useTokenListDecorator from '../Tokens/hooks/useTokenListDecorator';

type AccountContextReturnType = {
  isLoggedIn: boolean;
  esdtTokens: TTokenListDecorator;
  metaTokens: TTokenListDecorator;
  account: AccountModel;
};

export const AccountContext = createContext<AccountContextReturnType>({} as AccountContextReturnType);
export const AccountProvider = ({ children }: { children: JSX.Element }) => {
  const account = useGetAccountInfo();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { provider } = useGetAccountProvider();

  const [metaTokens, setMetaTokens] = useState([]);
  const [esdtTokens, setEsdtTokens] = useState([]);

  const decoratedEsdtTokens: TTokenListDecorator = useTokenListDecorator(esdtTokens);
  const decoratedMetaEsdtTokens: TTokenListDecorator = useTokenListDecorator(metaTokens);

  const [accountInformation, setAccountInformation] = useState({});

  const accountModel = useMemo(() => {
    return new AccountModel().withProviderData(account).withAccountInformation(accountInformation);
  }, [account, accountInformation]);

  useInterval(() => {
    const isLoggedIn = getIsLoggedIn();
    setIsLoggedIn(isLoggedIn);
  }, 300);

  useEffect(() => {
    provider &&
      provider.init &&
      provider.init().then(() => {
        /* this is not empty */
      });

    return () => {
      /** do nothing **/
    };
  }, []);

  useEffect(() => {
    let isRunning = true;
    const address = account.address;

    if (isLoggedIn || (address || '').length > 0) {
      AccountService.getInformation(address).then(data => isRunning && setAccountInformation(data));

      AccountService.getEsdtTokens(address)
        .then(setEsdtTokens)
        .catch(() => {
          /* do nothing */
        });

      AccountService.getMetaEsdtTokens(address)
        .then(tokens => isRunning && setMetaTokens(tokens))
        .catch(() => {
          /* do nothing */
        });
    }

    return () => {
      isRunning = false;
    };
  }, [account.address, isLoggedIn]);

  const reloadTokens = () => {
    if (!account.address) return;

    AccountService.getInformation(account.address)
      .then(data => setAccountInformation(data))
      .catch(() => {
        /* do nothing */
      });

    AccountService.getEsdtTokens(account.address)
      .then(tokens => setEsdtTokens(tokens))
      .catch(() => {
        /* do nothing */
      });

    AccountService.getMetaEsdtTokens(account.address)
      .then(tokens => setMetaTokens(tokens))
      .catch(() => {
        /* do nothing */
      });
  };

  useInterval(reloadTokens, 20000);

  return (
    <AccountContext.Provider
      value={{
        isLoggedIn,
        esdtTokens: decoratedEsdtTokens,
        metaTokens: decoratedMetaEsdtTokens,
        account: accountModel,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
