import React, { memo } from 'react';
import styled from 'styled-components';

import Typography from '../../../../components/Atomic/Typography';
import MaxWidthContainer from '../../../../components/Layout/Containers/MaxWidthContainer';
import EmptyExclusivePools from '../../../../components/Pools/EmptyState/EmptyExclusivePools';

const ScreenContainer = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 100vh;
`;

const ContentContainer = styled(MaxWidthContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.sizes.xl}px;
  --spacing-vertical: ${props => props.theme.sizes.xxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    --spacing-vertical: ${props => props.theme.sizes.xxl}px;
  }

  margin-top: var(--spacing-vertical);
  margin-bottom: var(--spacing-vertical);
`;

const HeroSectionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.sm}px;
`;

function ExclusivePoolsListScreen() {
  return (
    <ScreenContainer>
      <ContentContainer>
        <HeroSectionTextWrapper>
          <Typography.H1>Exclusive</Typography.H1>
          <Typography.Copy size={'large'}>
            Subscribe and claim tokens from the exclusive distribution pools available
          </Typography.Copy>
        </HeroSectionTextWrapper>

        <EmptyExclusivePools />
      </ContentContainer>
    </ScreenContainer>
  );
}

export default memo(ExclusivePoolsListScreen);
