import SerializableModel from '../../../Core/models/serializable.model';
import LessonButtonModel from './lesson.button.model';

export default class LessonModel extends SerializableModel {
  id: string;
  type: string;

  title: string;
  content: string[];

  buttons: LessonButtonModel[];
}
