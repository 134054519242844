import React, { memo } from 'react';
import styled from 'styled-components';

import DefaultButton from '../../Atomic/Buttons/DefaultButton';
import EarnProgramModel from '../../../modules/Earn/models/earn.program.model';
import Icon from '../../Atomic/Icon';
import { useEsdtToken } from '../../../modules/Tokens/hooks/useTokens';
import { TokenIcon } from '../../Tokens/TokenIcon';
import Typography from '../../Atomic/Typography';
import { EARN_LIST_ROUTE } from '../../../modules/Navigation/NavigationConstants';

const Container = styled.div`
  --color: ${props => props.theme.colors.primary};
  --bg-color: ${props => props.theme.colors.border};
  --spacing: ${props => props.theme.sizes.md}px;

  flex: 1;
  display: flex;
  flex-direction: column;

  border-radius: var(--default-border-radius);
  background: ${props => props.theme.colors.gradient};
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${props => props.theme.sizes.xs}px;
  padding: var(--spacing);
  font-weight: bold;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: calc(var(--spacing) * 2) var(--spacing);
`;

const FooterContainer = styled.div`
  display: flex;
  gap: var(--spacing);
  padding: var(--spacing);
  justify-content: space-between;
  background-color: rgba(14, 15, 21, 0.6);
`;

const StatsContainer = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: max-content max-content;
  column-gap: ${props => props.theme.sizes.xxs}px;
  row-gap: ${props => props.theme.sizes.xxs}px;

  font-size: 0.85em;

  @media all and ${({ theme: { device } }) => device.xs} {
    flex-direction: column;
    align-items: flex-start;
    grid-template-columns: 1fr;
  }
`;

const ParticipantsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.sizes.xxs}px;

  grid-row-start: 1;
  grid-row-end: 3;
  grid-column: 2;

  @media all and ${({ theme: { device } }) => device.xs} {
    grid-column: 1;
    grid-row-start: 3;
  }
`;

type Props = {
  program: EarnProgramModel;
};

function PoolItem(props: Props) {
  const { program } = props;
  const token = useEsdtToken(program.tokenIdentifier);

  return (
    <Container>
      <HeaderContainer>
        <TokenIcon size={36} token={token} />
        <Typography.Copy size={'large'}>{program.name}</Typography.Copy>
      </HeaderContainer>

      <ContentContainer>
        <Typography.Copy>
          Learn about <b>{program.name}</b>, complete the quiz and earn{' '}
          <b>
            {program.earnAmount.toLocaleString()} {token.ticker}
          </b>{' '}
          tokens
        </Typography.Copy>
      </ContentContainer>

      <FooterContainer>
        <StatsContainer>
          <ParticipantsContainer>
            <Icon name={'person'} />
            <span>
              {`${program.participants.toLocaleString()}`}
              {program.maxNumberOfParticipants && ` / ${program.maxNumberOfParticipants.toLocaleString()}`}
            </span>
          </ParticipantsContainer>
        </StatsContainer>
        <DefaultButton mode={'outline'} to={`${EARN_LIST_ROUTE}/${program.id}`}>
          Learn & Earn
        </DefaultButton>
      </FooterContainer>
    </Container>
  );
}

export default memo(PoolItem);
