import styled from 'styled-components';

const Typography = styled.div`
  line-height: 1;
`;

const Heading = styled(Typography)``;

const H1 = styled(Heading)`
  font-size: 4.2em;

  @media all and ${({ theme: { device } }) => device.sm} {
    font-size: 3.8em;
  }
`;

const H2 = styled(Heading)`
  font-size: 3em;

  @media all and ${({ theme: { device } }) => device.sm} {
    font-size: 2.2em;
  }
`;

const H3 = styled(Heading)`
  font-size: 1.9em;
`;

const H4 = styled(Heading)`
  font-size: 1.3em;
`;

const H5 = styled(Heading)``;
const H6 = styled(Heading)``;

const Copy = styled(Typography)<{ size?: 'large' | 'small' | 'tiny' }>`
  font-size: 1em;
  line-height: 1.3;

  ${({ size }) =>
    size === 'large' &&
    `
    font-size: 1.2em;
  `}

  ${({ size }) =>
    size === 'small' &&
    `
    font-size: 0.85em;
  `}

  ${({ size }) =>
    size === 'tiny' &&
    `
    font-size: 0.7em;
  `}
`;

export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Copy,
};
