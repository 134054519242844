import NftTokenModel from './models/NftTokenModel';
import EsdtTokenModel from './models/EsdtTokenModel';

import Environment from '../Environment';
import { TOKEN_TYPE_ESDT, TOKEN_TYPE_META_ESDT } from './TokensContstants';

export const LockedMexToken = new NftTokenModel({
  name: 'LockedMEX',
  type: TOKEN_TYPE_META_ESDT,
  collection: Environment.tokens.special.LKMEX.collectionName,
  identifier: Environment.tokens.special.LKMEX.collectionName,
  ticker: Environment.tokens.special.LKMEX.ticker,
  decimals: 18,
  nonce: 0,
  creator: undefined,
  attributes: undefined,
  balance: undefined,
  isWhitelistedStorage: undefined,
  assets: {
    status: 'active',
    pngUrl: 'https://media.elrond.com/tokens/asset/LKMEX-aab910/logo.png',
    svgUrl: 'https://media.elrond.com/tokens/asset/LKMEX-aab910/logo.svg',
  },
});

export const EgldToken = new EsdtTokenModel({
  name: Environment.tokens.special.EGLD.name,
  type: TOKEN_TYPE_ESDT,
  identifier: Environment.tokens.special.EGLD.identifier,
  ticker: Environment.tokens.special.EGLD.identifier,
  decimals: 18,
  nonce: 0,
  creator: undefined,
  attributes: undefined,
  balance: undefined,
  isWhitelistedStorage: undefined,
  assets: {
    status: 'active',
    svgUrl: '/assets/media/egld-logo.svg',
  },
});
