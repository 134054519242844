export const AUTH_ROUTE = '/app/auth';

export const HOMEPAGE_ROUTE = '/';
export const POOLS_ROUTE = '/pools';
export const EXCLUSIVE_POOLS_ROUTE = '/pools/exclusive';
export const CREATE_POOL_ROUTE = '/pools/create';

export const EARN_LIST_ROUTE = '/earn';

export const ABOUT_ROUTE = '/about';
export const NOT_FOUND_ROUTE = '/404';

export const LANDING_PAGE_ROUTES = [HOMEPAGE_ROUTE, NOT_FOUND_ROUTE];
