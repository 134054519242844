import React, { memo } from 'react';
import styled from 'styled-components';

import PoolsList from '../../../../components/Pools/PoolsList';
import Typography from '../../../../components/Atomic/Typography';
import MaxWidthContainer from '../../../../components/Layout/Containers/MaxWidthContainer';
import AddYourPool from '../../../../components/Pools/EmptyState/AddYourPool';

import pools from '../../../../modules/Pools/seed.data';

const ScreenContainer = styled.div`
  display: flex;
`;

const ContentContainer = styled(MaxWidthContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.sizes.xl}px;
  --spacing-vertical: ${props => props.theme.sizes.xxl * 2}px;

  @media all and ${({ theme: { device } }) => device.md} {
    --spacing-vertical: ${props => props.theme.sizes.xxl}px;
  }

  margin-top: var(--spacing-vertical);
  margin-bottom: var(--spacing-vertical);
`;

const HeroSectionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizes.sm}px;
`;

function PoolsListScreen() {
  return (
    <ScreenContainer>
      <ContentContainer>
        <HeroSectionTextWrapper>
          <Typography.H1>Pools</Typography.H1>
          <Typography.Copy size={'large'}>
            Subscribe and claim tokens from the distribution pools available
          </Typography.Copy>
        </HeroSectionTextWrapper>

        <PoolsList pools={pools} />

        <AddYourPool />
      </ContentContainer>
    </ScreenContainer>
  );
}

export default memo(PoolsListScreen);
